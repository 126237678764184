import { format } from "date-fns";

export const getWeekDay = (locale: Locale, date: Date): string => {
  return format(date, "EEEEEE", { locale })
};

export const ticksToDateTime = (ticks: number) => {
  var ticksToMicrotime = ticks / 10000;
  //ticks are recorded from 1/1/1; get microtime difference from 1/1/1/ to 1/1/1970
  var epochMicrotimeDiff = Math.abs(new Date(0, 0, 1).setFullYear(1));
  //new date is ticks, converted to microtime, minus difference from epoch microtime
  var tickDate = new Date(ticksToMicrotime - epochMicrotimeDiff);
  return tickDate;
};

export const getDateOnlyString = (date: string) => {
  const dt = new Date(date);
  const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
  return format(dtDateOnly, "dd/MM/yyyy");
};

export const getDateOnly = (date: Date) => {
  const dtDateOnly = new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);
  return dtDateOnly
}

export const getAge = (dateOfBirth?: Date) => {
  if (dateOfBirth) {
    var today = new Date();
    var age = today.getFullYear() - dateOfBirth.getFullYear();
    var m = today.getMonth() - dateOfBirth.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < dateOfBirth.getDate())) {
      age--;
    }
    return age;
  }
  return 0;
};

export const combineDateAndTime = (date: Date, time: string) => {
  const newDate = new Date(date);

  // Extract hours and minutes from the departureTime
  const [hours, minutes] = time.split(':').map(Number);

  // Set hours and minutes in the date object
  newDate.setUTCHours(hours, minutes, 0, 0);

  // Format the date object to the desired ISO string format
  return newDate.toISOString();
}
