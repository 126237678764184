import { BookingPackageFlight, PackageFlight } from "@qite/tide-client/build/types";
import { FlightMetaData, MetaDataType } from "@qite/tide-client";
import { first, last } from "lodash";
import { Flight } from "../site-types";

export const getFlightLocations = (flight: PackageFlight, type: string) => {
  if (flight && flight.metaDatas.length && flight.metaDatas.filter((m) => m.metaDataType === MetaDataType.flight).length) {
    const metadata = flight.metaDatas.find((m) => m.metaDataType === MetaDataType.flight);
    if (metadata) {
      const flightMetadata: FlightMetaData = JSON.parse(metadata.configuration);
      switch (type) {
        case "departure":
          return first(flightMetadata.flightLines)?.departureAirportDescription;
        case "arrival":
          return last(flightMetadata.flightLines)?.arrivalAirportDescription;
        default:
          ""
      }
      return `${first(flightMetadata.flightLines)?.departureAirportDescription} > ${last(flightMetadata.flightLines)?.arrivalAirportDescription}`;
    }
  }
  return "";
};

export const getBookingFlightLocations = (flight: BookingPackageFlight, type: string) => {
  if (flight && flight.flightMetaData) {
    switch (type) {
      case "departure":
        return first(flight.flightMetaData.flightLines)?.departureAirportDescription;
      case "arrival":
        return last(flight.flightMetaData.flightLines)?.arrivalAirportDescription;
      default:
        ""
    }
  }
  return "";
};

export const checkSameFlight = (flight1: Flight, flight2: Flight) => {
  if (!flight1 || !flight2) {
    return false;
  }

  if (flight1.externalGuid && flight2.externalGuid) {
    return flight1.externalGuid === flight2.externalGuid;
  }

  if (flight1.entryLineGuid && flight2.entryLineGuid && flight1.entryLineGuid !== "00000000-0000-0000-0000-000000000000" && flight2.entryLineGuid !== "00000000-0000-0000-0000-000000000000") {
    return flight1.entryLineGuid === flight2.entryLineGuid;
  }

  return false;
}