import { BookingPackage, BookingPackageFlight, BookingPackageFlightPool, BookingPackageHotel, BookingPackageHotelOption, BookingPackageItem, BookingPackageLine, BookingPackageRequestRoom, BookingPackageRoomOption, PerPackageOption, PerUnitPackageOption } from "@qite/tide-client/build/types";
import { ServiceType } from "@qite/tide-client";
import { concat, first, orderBy, last } from "lodash";
import { ProductCardItem, ProductCardItemPackage } from "../components/cardgrid/cards/product-card";
import { TideItemForHardcodedUrl, TideItemForTeam, TideItemForToernooi, TideItemForWedstrijd } from "../types";
import { findGameById, findSeasonGameById, findTournamentById, getChildItemsWithTypename, getSportPath } from "./component";
import { Image } from "../types";
import { getPlaceHolderImage } from "./image-utils";
import { Flight, FlightLine, FlightPool, Ticket, FlightMetaData, Hotel, Room } from "../site-types";
import { BookingPackageOption } from "@qite/tide-client/build/types/offer/booking-v2/shared/booking-package-option";
import { combineDateAndTime } from "./date-utils";
import { checkSameFlight } from "./flight-utils";

export const flightReduction = "FLIGHT REDUCTION";
export const hotelReduction = "HOTEL REDUCTION";
export const ticketReduction = "TICKET REDUCTION";

type GroupedBookingPackageItems = {
  [eventId: string]: BookingPackageItem[];
};

export const CreateProductCardItems = (
  packageItems: BookingPackageItem[],
  buttonText: string,
  withRootPath: boolean,
  teams: TideItemForTeam[],
  tournaments: TideItemForToernooi[],
  games: TideItemForWedstrijd[],
  affiliate: string
) => {
  if (packageItems) {
    const groupedItems = groupByEventId(packageItems);

    const newItems = Object.keys(groupedItems).map((eventId) => {
      const firstItem = first(groupedItems[eventId]);
      if (!firstItem) {
        return null;
      }

      const game = findGameById(games, eventId);
      if (!game) {
        return null;
      }

      const seasonGame = findSeasonGameById(game, eventId);
      const homeTeam = teams.find((t) => t.content?.general?.id === game?.content?.general?.homeTeamId);
      const awayTeam = teams.find((t) => t.content?.general?.id === game?.content?.general?.awayTeamId);
      const tournament = findTournamentById(tournaments, seasonGame?.content?.general?.tournamentId);
      let rootPath = process.env.WEBSITE === "Sportreizen" && game ? getSportPath(game) : "/wedstrijden/";

      const packageView = firstItem.includedServiceTypes.includes(ServiceType.package);
      return {
        title: packageView ? firstItem.name : firstItem.allotment?.name,
        image: game?.content?.general?.thumbnail?.url
          ? game.content.general.thumbnail
          : tournament?.content?.general?.thumbnail?.url
            ? tournament.content.general.thumbnail
            : ({ url: getPlaceHolderImage(), altText: firstItem.allotment?.name, title: firstItem.allotment?.name } as Image),
        homeLogo: homeTeam?.content?.general?.logo,
        awayLogo: awayTeam?.content?.general?.logo,
        path: `${rootPath}${game?.content?.general?.path}?tourcode=${firstItem.allotment?.tourCode}&flights=${firstItem.includedServiceTypes.includes(
          ServiceType.flight
        )}`,
        startDate: new Date(firstItem.fromDate),
        endDate: new Date(firstItem.toDate),
        ticketName: firstItem.accommodationName,
        ticketDate: new Date(firstItem.allotment.startDate),
        dateConfirmed: firstItem.allotment?.customAllotmentStatus === "Datum bevestigd",
        tournamentName: tournament?.name,
        hardcodedUrls: getChildItemsWithTypename("TideItemForHardcodedUrl", seasonGame) as TideItemForHardcodedUrl[],
        packages: groupedItems[eventId].map((item) => {
          let path = `/boeking?ProductCode=${item.code}&CatalogId=${item.catalogueId}&From=${item.fromDate.substring(0, 10)}&To=${item.toDate.substring(0, 10)}&Tourcode=${item.allotment?.tourCode
            }&EventId=${eventId}&ServiceTypes=${item.includedServiceTypes}${affiliate && affiliate !== "" ? "&vrp=" + affiliate : ""}`;
          if (item.includedServiceTypes.includes(ServiceType.package)) {
            path = `/boeking?ProductCode=${item.code}&CatalogId=${item.catalogueId}&From=${item.fromDate.substring(0, 10)}&To=${item.toDate.substring(0, 10)}&EventId=${eventId}&ServiceTypes=${item.includedServiceTypes}${affiliate && affiliate !== "" ? "&vrp=" + affiliate : ""}`;
          }
          return {
            path: path,
            price: item.averagePricePerPerson,
            hasFlight: item.includedServiceTypes.includes(ServiceType.flight),
            hasHotel: item.includedServiceTypes.includes(ServiceType.hotel),
            hasEvent: item.includedServiceTypes.includes(ServiceType.event),
            buttonText: buttonText,
          } as ProductCardItemPackage;
        }),
      };
    });

    return newItems.filter((f) => f !== null) as ProductCardItem[];
  }
  return;
};

export const getCacheReduction = (bookingPackage: BookingPackage | undefined, type: string) => {
  return (
    (bookingPackage?.options
      .find((o) => o.isSelected)
      ?.groups.flatMap((g) => g.options)
      ?.find((o) => o.line?.productCode === type)?.line?.price ?? 0) / 2
  );
};

export const createDataLayerItemsFromDetails = (bookingPackage: BookingPackage | undefined) => {
  let items = [];
  const option = bookingPackage?.options.find((o) => o.isSelected === true);
  if (option) {
    // ticket
    const ticket = option.rooms.flatMap((r) => r.options).find((o) => o.isSelected === true);
    if (ticket) {
      items.push({
        item_id: ticket.accommodationCode,
        item_name: ticket.accommodationName,
        item_start_date: ticket.from,
        item_end_date: ticket.to,
        item_category: "Ticket",
        quantity: ticket.quantity,
        price: ticket.price,
      });
    }

    //hotel
    const hotelrooms = option.optionUnits.flatMap((o) => o.groups.flatMap((g) => g.options)).filter((o) => o.isHotelPool && o.isSelected);
    if (hotelrooms) {
      hotelrooms.map((h) => {
        items.push({
          item_id: h.line?.productCode,
          item_name: h.line?.productName,
          item_start_date: h.line?.startDate,
          item_end_date: h.line?.endDate,
          item_category: "Hotel",
          item_category2: h.line?.accommodationCode,
          item_category3: h.line?.regimeCode,
          quantity: 1,
          price: h.line?.price,
        });
      });
    }

    //flights
    const outwardFlight = bookingPackage?.outwardFlights.find((o) => o.isSelected);
    if (outwardFlight) {
      items.push({
        item_id: outwardFlight.code,
        item_name: outwardFlight.code,
        item_start_date: outwardFlight.startDateTime,
        item_end_date: outwardFlight.endDateTime,
        item_category: "Flight",
        quantity: option.requestRooms.flatMap((r) => r.pax).length,
        price: outwardFlight.price,
      });
    }
    const returnFlight = bookingPackage?.returnFlights.find((o) => o.isSelected);
    if (returnFlight) {
      items.push({
        item_id: returnFlight.code,
        item_name: returnFlight.code,
        item_start_date: returnFlight.startDateTime,
        item_end_date: returnFlight.endDateTime,
        item_category: "Flight",
        quantity: option.requestRooms.flatMap((r) => r.pax).length,
        price: returnFlight.price,
      });
    }

    // extras
    const extras = option.optionPax.flatMap((o) => o.groups.flatMap((g) => g.options)).filter((o) => o.isSelected);
    if (extras) {
      extras.map((extra) => {
        items.push({
          item_id: extra.line?.productCode,
          item_name: extra.line?.productName,
          item_start_date: extra.line?.startDate,
          item_end_date: extra.line?.endDate,
          item_category: "Extra",
          quantity: 1,
          price: extra.line?.price,
        });
      });
    }

    // reductions
    const cacheTresholds = option.groups
      .find((g) => g.name === "CACHE TRESHOLD")
      ?.options.filter((o) => o.isSelected && o.line && o.line.price !== 0);
    if (cacheTresholds) {
      cacheTresholds.map((cacheTreshold) => {
        items.push({
          item_id: cacheTreshold.line?.productCode,
          item_name: cacheTreshold.line?.productName,
          item_start_date: cacheTreshold.line?.startDate,
          item_end_date: cacheTreshold.line?.endDate,
          item_category: "Reduction",
          quantity: 1,
          price: cacheTreshold.line?.price,
        });
      });
    }
    return items;
  }
};

export const createDataLayerItemsFromSearch = (packageItem: BookingPackageItem, requestRooms: BookingPackageRequestRoom[]) => {
  if (packageItem) {
    let items = [
      {
        item_id: packageItem.allotment?.tourCode,
        item_name: packageItem.allotment?.name,
        item_location: packageItem.name,
        item_start_date: packageItem.allotment?.startDate,
        item_end_date: packageItem.allotment?.endDate,
        item_category: "Ticket",
        quantity: requestRooms.flatMap((r) => r.pax).length,
      },
      {
        item_id: packageItem.hotelProductCode,
        item_name: packageItem.hotelProductCode,
        item_location: packageItem.locationName,
        item_start_date: packageItem.fromDate,
        item_end_date: packageItem.toDate,
        item_category: "Hotel",
        quantity: requestRooms.length,
      },
    ];
    if (packageItem.includedServiceTypes.includes(7)) {
      items.push({
        item_id: packageItem.outwardFlightCode,
        item_name: packageItem.flightDescription,
        item_location: packageItem.airportCode,
        item_start_date: packageItem.outwardFlightStartDate || "",
        item_end_date: packageItem.returnFlightEndDate || "",
        item_category: "Flight",
        quantity: requestRooms.flatMap((r) => r.pax).length,
      });
    }
    return items;
  }
  return null;
};

const groupByEventId = (items: BookingPackageItem[]) => {
  const orderedItems = items
    .filter((i) => i !== null)
    .reduce<GroupedBookingPackageItems>((acc, item) => {
      const eventId = item.allotment.tourCode.split("|")[0];
      if (!acc[eventId]) {
        acc[eventId] = [];
      }
      acc[eventId].push(item);
      return acc;
    }, {});

  // Sort each group by ServiceType.flight and ServiceType.hotel
  Object.keys(orderedItems).forEach(eventId => {
    orderedItems[eventId] = orderBy(orderedItems[eventId],
      [
        item => item.includedServiceTypes.includes(ServiceType.flight),
        item => item.includedServiceTypes.includes(ServiceType.hotel)
      ],
      ['desc', 'desc']);
  });

  return orderedItems;
};

export const getSubserviceFromPackage = (bookingPackage: BookingPackage | undefined, serviceType: number, index: number | undefined = undefined) => {
  let subServices = [] as PerPackageOption[];
  const selectedOption = bookingPackage?.options.find((o) => o.isSelected);
  if (bookingPackage && selectedOption) {
    // get subServices per booking
    const servicePerBooking = selectedOption.groups.flatMap(g => g.options).filter(o => o.line?.serviceType === serviceType);
    // get subServices per kamer
    const servicePerRoom = index === undefined ?
      selectedOption.optionUnits.flatMap(r => r.groups).flatMap(g => g.options).filter(o => o.line?.serviceType === serviceType) :
      selectedOption.optionUnits.filter(g => g.index === index).flatMap(r => r.groups).flatMap(g => g.options).filter(o => o.line?.serviceType === serviceType);
    // get subServices per pax
    const servicePerPax = selectedOption.optionPax.flatMap(p => p.groups).flatMap(g => g.options).filter(o => o.line?.serviceType === serviceType);
    subServices.push(...concat(servicePerBooking, servicePerRoom, servicePerPax));
    return subServices;
  }
  return [];
}

export const switchSubServiceWithAlternative = (bookingPackage: BookingPackage, oldEntryLineGuid: string, newEntryLineGuid: string) => {
  const selectedOption = bookingPackage.options.find(o => o.isSelected);
  if (selectedOption) {
    let oldValue = findOptionByGuid(selectedOption, oldEntryLineGuid);
    const clonedOldValue = { ...oldValue };
    if (oldValue) {
      let alternatifIndex = oldValue.alternatives.findIndex(a => a.entryLineGuid === newEntryLineGuid);
      if (alternatifIndex !== -1) {
        oldValue.line = { ...oldValue.alternatives[alternatifIndex] };
        oldValue.alternatives[alternatifIndex] = { ...clonedOldValue.line } as BookingPackageLine;
      }
    }
  }
}

const findOptionByGuid = (selectedOption: BookingPackageOption, targetGuid: string) => {
  for (const group of selectedOption.groups) {
    for (const optionItem of group.options) {
      if (optionItem.line.entryLineGuid === targetGuid) {
        return optionItem;
      }
    }
  }
  for (const optionUnit of selectedOption.optionUnits) {
    for (const group of optionUnit.groups) {
      for (const optionItem of group.options) {
        if (optionItem.line.entryLineGuid === targetGuid) {
          return optionItem;
        }
      }
    }
  }
  for (const pax of selectedOption.optionPax) {
    for (const group of pax.groups) {
      for (const optionItem of group.options) {
        if (optionItem.line.entryLineGuid === targetGuid) {
          return optionItem;
        }
      }
    }
  }
  return null; // If no matching option is found
}

export const mapRoomOptionsToTickets = (roomOptions: BookingPackageRoomOption[]) => {
  return roomOptions.map(o => {
    return {
      entryLineGuid: o.entryLineGuid,
      productCode: o.productCode,
      accommodationName: o.accommodationName,
      accommodationCode: o.accommodationCode,
      externalAccommodationCode: o.externalAccommodationCode,
      isSelected: o.isSelected,
      price: o.price,
      tourCode: o.tourCode,
      from: o.from,
      to: o.to,
    } as Ticket;
  });
}

export const mapPerPackageOptionToTicket = (perPackageOption: PerPackageOption) => {
  const tickets = perPackageOption.alternatives.map(a => {
    return {
      entryLineGuid: a.entryLineGuid,
      productCode: a.productCode,
      accommodationName: a.accommodationName,
      accommodationCode: a.accommodationCode,
      isSelected: false,
      price: a.price,
      tourCode: a.allotmentTourCode,
      from: a.startDate,
      to: a.endDate,
    } as Ticket;
  });

  tickets.unshift({
    entryLineGuid: perPackageOption.line?.entryLineGuid,
    productCode: perPackageOption.line?.productCode,
    accommodationName: perPackageOption.line?.accommodationName,
    accommodationCode: perPackageOption.line?.accommodationCode,
    isSelected: perPackageOption.isSelected,
    price: perPackageOption.line?.price,
    tourCode: perPackageOption.line?.allotmentTourCode,
    from: perPackageOption.line?.startDate,
    to: perPackageOption.line?.endDate,
  } as Ticket);

  return tickets;
}

export const mapBookingPackageFlightPoolToFlightPool = (bookingPackageFlightPool: BookingPackageFlightPool) => {
  return {
    outwardFlights: bookingPackageFlightPool.outwardFlights.map(f => mapBookingPackageFlightToFlight(f)),
    returnFlights: bookingPackageFlightPool.returnFlights.map(f => mapBookingPackageFlightToFlight(f)),
  } as FlightPool
}

const mapBookingPackageFlightToFlight = (bookingPackageFlight: BookingPackageFlight) => {
  return {
    externalGuid: bookingPackageFlight.externalGuid,
    entryLineGuid: bookingPackageFlight.entryLineGuid,
    code: bookingPackageFlight.code,
    accommodationCode: bookingPackageFlight.class,
    price: bookingPackageFlight.price,
    startDateTime: bookingPackageFlight.startDateTime,
    endDateTime: bookingPackageFlight.endDateTime,
    isSelected: bookingPackageFlight.isSelected,
    flightMetaData: {
      durationInTicks: bookingPackageFlight.flightMetaData?.durationInTicks,
      flightLines: bookingPackageFlight.flightMetaData?.flightLines.map(fl => {
        return {
          number: fl.number,
          airline: fl.airlineCode,
          departureTime: fl.departureTime,
          departureDate: fl.departureDate,
          departureAirport: fl.departureAirport,
          departureAirportDescription: fl.departureAirportDescription,
          arrivalTime: fl.arrivalTime,
          arrivalDate: fl.arrivalDate,
          arrivalAirport: fl.arrivalAirport,
          arrivalAirportDescription: fl.arrivalAirportDescription,
        } as FlightLine
      })
    } as FlightMetaData
  } as Flight
}

export const mapBookingPackageLineFlightsToFlightPool = (outwardFlight: PerPackageOption, returnFlight: PerPackageOption) => {
  return {
    outwardFlights: mapPerPackageOptionToFlights(outwardFlight),
    returnFlights: mapPerPackageOptionToFlights(returnFlight)
  } as FlightPool
}

export const mapPerPackageOptionToFlights = (perPackageOption: PerPackageOption) => {
  const flights = perPackageOption.alternatives.map(a => mapBookingPackageLineToFlight(a, false));
  flights.unshift(mapBookingPackageLineToFlight(perPackageOption.line, perPackageOption.isSelected));

  return flights;
}

const mapBookingPackageLineToFlight = (bookingPackageLine: BookingPackageLine, selected: boolean) => {
  const firstFlightLine = first(bookingPackageLine.flightProperties?.flightLines);
  const lastFlightLine = last(bookingPackageLine.flightProperties?.flightLines);
  return {
    entryLineGuid: bookingPackageLine.entryLineGuid,
    code: bookingPackageLine.productCode,
    accommodationCode: bookingPackageLine.accommodationCode,
    price: bookingPackageLine.price,
    startDateTime: firstFlightLine ? combineDateAndTime(firstFlightLine.departureDate, firstFlightLine.departureTime) : bookingPackageLine.startDate,
    endDateTime: lastFlightLine ? combineDateAndTime(lastFlightLine.arrivalDate, lastFlightLine.arrivalTime) : bookingPackageLine.endDate,
    isSelected: selected,
    flightMetaData: {
      durationInTicks: bookingPackageLine.flightProperties?.durationInTicks,
      flightLines: bookingPackageLine.flightProperties?.flightLines?.map(fl => {
        return {
          number: fl.number,
          airline: fl.airlineCode,
          departureTime: fl.departureTime,
          departureDate: fl.departureDate,
          departureAirport: fl.departureAirport,
          departureAirportDescription: fl.departureAirportDescription,
          arrivalTime: fl.arrivalTime,
          arrivalDate: fl.arrivalDate,
          arrivalAirport: fl.arrivalAirport,
          arrivalAirportDescription: fl.arrivalAirportDescription,
        } as FlightLine
      })
    } as FlightMetaData
  } as Flight
}

export const setSelectedFlightInBookingPackage = (bookingPackage: BookingPackage, oldFlight: Flight, newFlight: Flight, type: string | undefined = undefined) => {
  const selectedOption = bookingPackage.options.find(o => o.isSelected);
  if (selectedOption?.includedServiceTypes.includes(ServiceType.package)) {
    switchSubServiceWithAlternative(bookingPackage, oldFlight.entryLineGuid, newFlight.entryLineGuid);
  } else {
    if (type === "outward") {
      for (const flight of bookingPackage.outwardFlights) {
        flight.isSelected = false;
        if (checkSameFlight(mapBookingPackageFlightToFlight(flight), newFlight)) {
          flight.isSelected = true;
        }
      }
    } else if (type === "return") {
      for (const flight of bookingPackage.returnFlights) {
        flight.isSelected = false;
        if (checkSameFlight(mapBookingPackageFlightToFlight(flight), newFlight)) {
          flight.isSelected = true;
        }
      }
    }
  }
}

export const mapBookingPackageHotelToHotel = (bookingPackageHotel: BookingPackageHotel, startDate: string, endDate: string) => {
  return {
    productCode: bookingPackageHotel.productCode,
    name: bookingPackageHotel.name,
    rooms: bookingPackageHotel.rooms.flatMap(r => r.options.map(o => mapBookingPackageHotelOptionToRoom(o))),
    cheapestPrice: bookingPackageHotel.cheapestPrice,
    hotelSupplier: bookingPackageHotel.hotelSupplier,
    description: bookingPackageHotel.description,
    rating: bookingPackageHotel.rating,
    stars: bookingPackageHotel.stars,
    facilities: bookingPackageHotel.facilities,
    imageUrl: bookingPackageHotel.imageUrl,
    travelDurationToVenue: bookingPackageHotel.travelDurationToVenue,
    travelDurationToCenter: bookingPackageHotel.travelDurationToCenter,
    distanceToVenue: bookingPackageHotel.distanceToVenue,
    distanceToCenter: bookingPackageHotel.distanceToCenter,
    isSelected: bookingPackageHotel.isSelected,
    startDate: startDate,
    endDate: endDate,
    longitude: bookingPackageHotel.longitude,
    latitude: bookingPackageHotel.latitude,
  } as Hotel;
}

const mapBookingPackageHotelOptionToRoom = (bookingPackageRoomOption: BookingPackageHotelOption) => {
  return {
    entryLineGuid: bookingPackageRoomOption.entryLineGuid,
    accommodationName: bookingPackageRoomOption.accommodationName,
    accommodationCode: bookingPackageRoomOption.accommodationCode,
    regimeCode: bookingPackageRoomOption.regimeCode,
    regimeName: bookingPackageRoomOption.regimeName,
    isSelected: bookingPackageRoomOption.isSelected,
    price: bookingPackageRoomOption.price,
    roomIndex: bookingPackageRoomOption.roomIndex,
  } as Room;
}

export const getHotelsFromBookingPackage = (bookingPackage: BookingPackage | undefined) => {
  if (!bookingPackage) {
    return [];
  }

  const selectedOption = bookingPackage.options.find(o => o.isSelected);
  if (!selectedOption) {
    return [];
  }

  if (selectedOption.includedServiceTypes.includes(ServiceType.package)) {
    const roomIndexes = selectedOption.requestRooms.map(r => r.index);
    const hotels = [] as Hotel[];
    roomIndexes.map(roomIndex => {
      const hotelSubServices = getSubserviceFromPackage(bookingPackage, ServiceType.hotel, roomIndex);
      hotelSubServices.map(hss => hotels.push(mapPerPackageOptionToHotel(hss, roomIndex)));
    })
    return hotels;
  }
}

const mapPerPackageOptionToHotel = (perPackageOption: PerPackageOption, roomIndex: number) => {
  const hotel = mapBookingPackageLineToHotel(perPackageOption.line, perPackageOption.isSelected, roomIndex);
  perPackageOption.alternatives.map(a => hotel.rooms.push(mapBookingPackageLineToHotelRoom(a, false, roomIndex)));
  return hotel;
}

const mapBookingPackageLineToHotel = (bookingPackageLine: BookingPackageLine, selected: boolean, roomIndex: number) => {
  return {
    productCode: bookingPackageLine.productCode,
    name: bookingPackageLine.productName,
    hotelSupplier: 0, // Always local hotel in package for now
    rooms: [mapBookingPackageLineToHotelRoom(bookingPackageLine, true, roomIndex)] as Room[],
    description: "",
    rating: "",
    stars: "",
    facilities: "",
    imageUrl: "",
    cheapestPrice: bookingPackageLine.price,
    isSelected: selected,
    startDate: bookingPackageLine.startDate,
    endDate: bookingPackageLine.endDate,
    longitude: 0,
    latitude: 0,
  } as Hotel;
}

const mapBookingPackageLineToHotelRoom = (bookingPackageLine: BookingPackageLine, selected: boolean, roomIndex: number) => {
  return {
    entryLineGuid: bookingPackageLine.entryLineGuid,
    accommodationName: bookingPackageLine.accommodationName,
    accommodationCode: bookingPackageLine.accommodationCode,
    regimeCode: bookingPackageLine.regimeCode,
    regimeName: bookingPackageLine.regimeName,
    isSelected: selected,
    price: bookingPackageLine.price,
    roomIndex: roomIndex,
  } as Room
}

export const setNewHotelInBookingPackageUnitOptions = (selectedHotel: Hotel, bookingPackage: BookingPackage, bookingSearch: BookingPackageItem | undefined) => {
  const detail = bookingPackage?.options.find((o) => o.isSelected);
  if (detail) {
    if (detail.includedServiceTypes.includes(ServiceType.package)) {
      const subServices = getSubserviceFromPackage(bookingPackage, ServiceType.hotel);
      if (subServices) {
        subServices.map(subService => {
          subService.isSelected = subService.line.productCode === selectedHotel.productCode
          if (subService.isSelected) {
            const selectedRoom = selectedHotel.rooms.find(r => r.isSelected);
            if (selectedRoom) {
              switchSubServiceWithAlternative(bookingPackage, subService.line.entryLineGuid, selectedRoom.entryLineGuid);
            }
          }
          return subService;
        });
      }
    } else {
      detail.optionUnits.forEach((x) => (x.groups = x.groups.filter((y) => !y.options.some((z) => z.isHotelPool))));
      for (var index = 1; index < detail.optionUnits.length + 1; index++) {
        var unit = detail.optionUnits.find((x) => x.index == index);
        if (!unit) {
          unit = {
            index: index,
            groups: [],
            airlineGroups: [],
            airportGroups: [],
          };
          detail.optionUnits.push(unit);
        }
        var group = unit.groups.find((x) => x.name == "");
        if (!group) {
          group = {
            name: "",
            title: "",
            options: [],
          };
          unit.groups.push(group);
        }
        var alternatives: PerUnitPackageOption[] = [];
        selectedHotel.rooms
          .filter((r) => r.roomIndex == index)
          .map((room) => {
            var unitOption: PerUnitPackageOption = {
              alternatives: [],
              groups: [],
              isDefault: false,
              isHotelPool: true,
              isSelected: room.isSelected,
              line: {
                accommodationCode: room.accommodationCode,
                accommodationName: room.accommodationName,
                endDate: detail.toDate,
                entryLineGuid: room.entryLineGuid,
                price: room.price,
                productCode: selectedHotel.productCode,
                productName: selectedHotel.name,
                regimeCode: room.regimeCode,
                regimeName: room.regimeName,
                startDate: detail.fromDate,
                serviceType: ServiceType.hotel,
              } as BookingPackageLine,
              pax: [],
              requirementType: 1,
            } as PerUnitPackageOption;
            alternatives.push(unitOption);
          });

        var selected = alternatives.find((a) => a.isSelected);
        if (selected) {
          selected.alternatives = alternatives.filter((x) => x !== selected).map((a) => a.line);
          group.options.push(selected);
        }
      }

      // set the hotel reduction group if the selected hotel is the cached hotel
      var groups = bookingPackage.options.find((o) => o.isSelected)?.groups;
      if (groups) {
        for (const group of groups) {
          if (group.name === "CACHE TRESHOLD") {
            for (const option of group.options) {
              if (option.line.productCode === hotelReduction) {
                option.isSelected = checkIfStillCachedHotel(selectedHotel, bookingSearch);
              }
            }
          }
        }
      }
    }
  }
};


const checkIfStillCachedHotel = (selectedHotel: Hotel | undefined, bookingSearch: BookingPackageItem | undefined) => {
  if (!bookingSearch) return false;
  if (!selectedHotel) return false;

  return bookingSearch.hotelProductCode == selectedHotel.productCode;
};
